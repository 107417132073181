/*
@Title: Project File
@Description:
This file defines which sass files are necessary for the project.
There are 4 Import Types: 1. Node Modules, 2. Theme Config File, 3. Core Files, 4. Theme Files.
Please keep in mind, that the order is IMPORTANT and cannot be changed!!!!!!
*/

// Config
@use "../utilities/functions" as util;
@import '../../../cv/scss/organisms/cv-text';
@import '../../../cv/scss/organisms/cv-image';
@import '../../../cv/scss/organisms/cv-imgtxt';
@import '../../../cv/scss/organisms/cv-gallery';
@import '../../../cv/scss/organisms/cv-video';
@import '../../../cv/scss/organisms/cv-button';
@import '../../../cv/scss/organisms/cv-line';
@import '../../../cv/scss/organisms/cv-accordion';
@import "../components/message";
@import '../../../cv/scss/organisms/cv-message';
@import "../components/news-tile";
@import "../components/leadbuyer-tile";
@import "../components/service-tile";
@import "../components/carousel";

.module-cv {
	display: flex;
	flex-direction: column;
	gap: util.spacer(12);
}

.ce-wrapper {
	width: 100%;
	position: relative;
}

.cv-edit-button {
    position: absolute;
    width: 2rem;
    height: 2rem;
    background: #fff;
    bottom: 10px;
    right: 10px;
    border-radius: 1rem;
    box-shadow: 0px 1px 6px rgba(0,0,0,.27);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
	transform: scale(1);
	transition: transform .1s ease-in-out;

    &:hover {
		transform: scale(1.1);
    }
}