@use "../basics";

@use "../controls/button";
@use "../controls/form/index";
@use "../controls/search";
@use "../controls/dialog";
@use "../controls/tab";

@use "../components/table";
@use "../components/header";
@use "../components/footer";
@use "../components/navigation";
@use "../components/message";
@use "../components/filter";
@use "../components/article";
@use "../components/progress";
@use "../components/context-menu";
@use "../components/search-overlay";
@use "../components/kdmz-content-loading";
@use "../components/kdmz-loading-icon";
@use "../components/printorder-comments";
@use "../components/printorder-history";
@use "../components/printorder-timeline";
@use "../components/edit-field";

@use "../utilities/spacer";
@use "../utilities/visibility";
@use "../utilities/flex";
@use "../utilities/grid";

.module-printorder {

	grid-column: 1 / -1;

	.title-with-button-wrapper {
		ul {
			list-style: none;
			padding: 0px;
			margin: 0px;

			li {

				&.is-active {

					a {
						&:before {
							transform: translateY(0px);
						}
					}
				}

				a {
					display: block;
					position: relative;
					overflow: hidden;

					&:before {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height: 3px;
						background: var(--clr-primary);
						transition: transform 0.2s ease-in-out;
						transform: translateY(5px);
					}

					&:hover {
						&:before {
							transform: translateY(0px);
						}
					}
				}
			}
		}
	}

	.printorder-list {
		padding: 0px;
		margin: 0px;
		list-style: none;

		&-item {
			padding: 2rem 0px;
			border-bottom: 1px solid rgba(var(--clr-black-rgb), 0.2);
			display: grid;
			grid-template-rows: auto auto;
			column-gap: 1.5rem;
			row-gap: 2rem;

			@media (min-width: map-get(basics.$breakpoints, s)) {
				grid-template-rows: 1fr;
				grid-template-columns: 7.5rem auto;
			}

			@media (min-width: map-get(basics.$breakpoints, s)) {
				grid-template-columns: 13rem auto;
			}

			&__image {
				display: block;

				img {
					width: 100%;
					object-fit: contain;
					max-width: 75px;

					@media (min-width: map-get(basics.$breakpoints, s)) {
						max-width: 100%;
					}
				}
			}

			&__information {
				column-gap: 1.5rem;
			}

			&__specs {
				margin: 0px;
				column-gap: 1.5rem;
				row-gap: 0.5rem;
			}

			&__title {
				display: block;
				font-weight: bold;
				font-size: 1.8rem;
				line-height: 2.2rem;
				margin-bottom: 1rem;
				color: var(--clr-grey20);
				max-width: 450px;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__description {
				display: block;
				max-height: 120px;
				color: var(--clr-grey20);
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.printorder-detail-header {
		&__top {
			@media (min-width: map-get(basics.$breakpoints, m)) {
				display: grid;
				grid-template-columns: 25% 75%;
				column-gap: spacer.$spacer;
			}
		}

		.printorder-detail-image {
			img {
				max-width: 100%;
			}
		}
	}

	.printorder-action-wrapper {

		.printorder-forward-form {
			label {
				display: none;
			}
			select {
				height: map-get(button.$btn-sizes, basics.$default-size);
			}
			button {
				display: inline;
			}
		}
	}

	.printorder-detail-documents {

        padding: 0px;
        margin: 0px 0px 20px 0px;
        list-style: none;

        .document-wrapper {
            margin-bottom: 5px;
            background: var(--clr-grey95);
            transition: background 0.2s ease-in-out;
            padding: 4px 18px 4px 8px;
            display: flex;
            align-items: center;
            color: var(--clr-black);
            font-size: 16px;
            line-height: 19px;

            &:hover  {
                background: var(--clr-grey90);
            }

            &:first-of-type {
                margin-top: 20px;
            }

            .document-file-extension {
                margin-right: 10px;
                width: 32px;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            .document-title {
                flex-grow: 1;
                margin-right: 10px;
            }
        }
    }

	.printorder-documents {
		margin: 30px 0px;
        font-size: 16px;
        line-height: 19px;

		.uppy-Dashboard-inner {
			max-height: 250px;
		}

        ul {

            list-style: none;
            margin: 0px;
            padding: 0px;

            li {
                padding: 15px;
                margin: 5px 0px;
                background: var(--clr-grey95);
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:first-of-type {
                    margin-top: 5px;
                }

                &:last-of-type {
                    margin-bottom: 20px;
                }

                .dmas-document-filesize {
                    flex-basis: 75px;
					margin-left: auto;
                }

                .dmas-document-delete {
                    flex-basis: 20px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }

        button {
            display: inline-block;
            margin-left: auto;
        }
    }

	.vcard-entries {

        table {

            margin-left: -5px;
            margin-right: -5px;
            width: 100%;

            td {
                padding: 2.5px 5px;
                vertical-align: center;
            }

            thead {
                font-weight: bold;
            }

            tr  {
                > td:nth-child(2) {
                    width: 125px;
                }
            }

            tbody {

                tr {
                    display: none;

                    &.is-visible {
                        display: table-row;
                    }
                }

                input {
                    &.vcard-name {
                        width: 100%;
                    }

                    &.vcard-quantity {
                        max-width: 16rem;
                    }
                }
            }
        }
    }
}

.kdmz-printorder-address-selection {
	padding: 0px;
	margin: 0px;
	list-style: none;

	.address-selection-entry {
		padding: 5px;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;

		&:not(:last-child) {
			border-bottom: 1px solid var(--clr-black);
		}
	}
}